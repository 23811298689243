import { useStore } from "vuex";
import type { ShoppingCartItem } from "~/components/ecommerce/types/ShoppingCartItem";
import { ShoppingCartItemEnum } from "~/components/ecommerce/types/ShoppingCartItemEnum";
import { mapTradeInItem } from "~/helpers/ecommerce/shoppingCartItemMapper";
import type { TradeInInfo } from "~/components/hardware-and-insurance/types/TradeInInfo";
import type { ShoppingCartTradeInItem } from "~/components/hardware-and-insurance/types/ShoppingCartTradeInItem";
import validateImeiNumber from "~/helpers/validateImeiNumber";
import deviceReturns from "~/integrations/device-returns";
import type { TradeInInsuranceClaimData } from "~/components/hardware-and-insurance/types/TradeInInsuranceClaimData";
import type { TradeInSteps } from "~/components/hardware-and-insurance/types/TradeInSteps";
import type { TradeInGradingData } from "~/components/hardware-and-insurance/types/TradeInGradingData";

export const useTradeIn = (addTradeInBonus?: boolean) => {
	const store = useStore();
	const route = useRoute();
	const router = useRouter();
	const { openModal, closeModal } = useModal();

	const loading = ref(false);
	const needCustomerFeedback = ref(false);
	const phoneSearchData = ref();
	const deviceOrder = ref();
	const gradingQuestions = ref();
	const error = ref();

	const tradeInItem = computed<ShoppingCartTradeInItem>(() => {
		return store?.getters["shoppingCart/items"]?.find(
			(item: ShoppingCartItem<unknown>) => item.type === ShoppingCartItemEnum.TRADE_IN,
		);
	});

	const currentTerminal = computed(() => store.getters["checkout/currentTerminal"]);

	const isSubItem = computed(() => store?.getters["shoppingCart/items"]?.length > 1);

	const phoneData = computed(() => tradeInItem?.value?.metaData?.phoneData ?? {});

	const gradingAnswers = computed(() => tradeInItem?.value?.metaData?.gradingAnswers ?? []);

	const hasDeviceOrder = computed(() => !!deviceOrder.value?.orderId);

	const gradingData: ComputedRef<TradeInGradingData> = computed(() => tradeInItem?.value?.metaData?.gradingData);

	const insuranceClaimData = computed(() => tradeInItem?.value?.metaData?.insuranceClaimData ?? {});

	const steps = computed(() => tradeInItem?.value?.metaData?.steps ?? {});

	const bonusInfoObject = computed(() => {
		const newPhoneInfo =
			route?.query?.imei && route?.query?.gtin
				? { imei: route?.query?.imei as string, gtin: route?.query?.gtin as string }
				: undefined;

		//TODO add back in trade in bonus
		return addTradeInBonus
			? newPhoneInfo
				? newPhoneInfo
				: currentTerminal?.value
					? { imei: "0", gtin: currentTerminal?.value?.metadata?.gtin }
					: undefined
			: undefined;
	});

	const returnPath = computed(() => route?.query?.path);

	const newPhoneData = computed(() => tradeInItem?.value?.metaData?.newPhoneData);

	const canContinue = computed(() => {
		return (
			!!(
				phoneData?.value?.gtin ||
				(needCustomerFeedback?.value &&
					phoneData?.value?.manufacturer &&
					phoneData?.value?.model &&
					phoneData?.value?.memorySize)
			) &&
			!tradeInItem?.value?.metaData?.hasSwap &&
			!hasDeviceOrder?.value &&
			validateImeiNumber(phoneData?.value?.IMEINumber)
		);
	});

	const isSwap = computed(() => tradeInItem?.value?.metaData?.hasSwap);

	const searchForPhone = async ({
		imeiNumber,
		isLoggedIn = false,
		insuranceAgreement,
	}: {
		imeiNumber: string;
		isLoggedIn?: boolean;
		insuranceAgreement?: any;
	}) => {
		deviceOrder.value = null;
		const imeiNumberClean = imeiNumber.replace(/\D/g, "");

		if (imeiNumberClean && imeiNumberClean.toString().trim() !== "") {
			if (imeiNumberClean.length === 15 && validateImeiNumber(imeiNumberClean)) {
				try {
					loading.value = true;
					if (isLoggedIn) {
						await searchIMEILoggedIn(imeiNumberClean);
					} else {
						await searchIMEI(imeiNumberClean);
					}
					loading.value = false;

					checkForDeviceOrder(imeiNumberClean);

					if (phoneSearchData.value && phoneSearchData.value?.length > 1 && !hasDeviceOrder.value) {
						needCustomerFeedback.value = true;
					} else if (phoneSearchData.value && phoneSearchData.value?.length === 1 && !hasDeviceOrder.value) {
						const device = phoneSearchData.value?.find((d) => d.imei === imeiNumberClean);
						await addTradeInPhone(device, insuranceAgreement);
					}
				} catch (e) {
					console.error(e);
				}
			}
		}
	};

	const getGradingQuestions = async () => {
		try {
			gradingQuestions.value = await deviceReturns.getGradingQuestions();
		} catch (e) {
			console.error(e);
		}
	};

	const addTradeInPhone = async (device: any, insuranceAgreement?: any) => {
		const deviceAgreement = phoneSearchData?.value?.find((d: any) => d?.imei === device?.imei)?.agreement;

		await updateTradeInItem({
			tradeInInfo: {
				phoneData: {
					IMEINumber: device?.imei,
					manufacturer: device?.manufacturer,
					model: device?.model,
					gtin: device?.gtin,
					memorySize: device?.memorySize ?? device?.storage,
					name: `${device?.manufacturer} ${device?.model}`,
					color: device?.color,
					agreement: { ...deviceAgreement, ...insuranceAgreement },
					imageUrl: device?.imageUrl,
				},
				hasSwap: deviceAgreement?.hasSwap,
				newPhoneData: bonusInfoObject?.value,
			},
		});
	};

	const getEstimatedValue = async ({ answers, deviceInfo }) => {
		try {
			const orderInfo = newPhoneData?.value ? { ...deviceInfo, newSale: newPhoneData?.value } : deviceInfo;

			const result = await deviceReturns.getEstimatedValue(deviceInfo?.IMEINumber, {
				deviceInfo: orderInfo,
				answers,
				isBusiness: false,
			});

			return { gradingData: result };
		} catch (e) {
			if (e.response?.data?.errorCode === 400020) {
				return true;
			}

			const existingSwapOrderId =
				e?.response?.data?.description?.split("ID:")?.[1] || e?.response?.data?.data?.description?.split("ID:")?.[1];
			if (existingSwapOrderId) {
				return { existingSwapOrderId: existingSwapOrderId };
			} else if (e.response?.data?.description?.includes("106")) {
				return { imeiHasOrder: true };
			} else {
				return false;
			}
		}
	};

	const submitInsuranceClaim = async (insuranceClaimForm) => {
		try {
			await deviceReturns.submitInsuranceClaim(gradingData?.value?.deviceReturnId, insuranceClaimForm);
			await setInsuranceClaimData(insuranceClaimForm);

			return true;
		} catch (e) {
			console.error(e);
			if (e?.response?.data?.description?.toLowerCase()?.indexOf("date of damage") > -1) {
				await setInsuranceClaimOutsideDateError(true);
				return false;
			}
			await setInsuranceClaimErrorStatus(true);
			return false;
		}
	};

	const setInsuranceClaimData = async (insuranceClaimData: TradeInInsuranceClaimData) => {
		await updateTradeInItem({
			tradeInInfo: {
				insuranceClaimData: insuranceClaimData,
			},
		});
	};
	const setInsuranceClaimErrorStatus = (state: boolean) => {
		error.value = {
			submitError: {
				status: state,
				message: "Noe gikk galt. Prøv igjen eller kontakt kundeservice hvis problemet gjentar seg.",
			},
		};
	};

	const setInsuranceClaimOutsideDateError = (state: boolean) => {
		error.value = {
			outsideInsuranceDateError: {
				status: state,
				message: "Forsikringen din var ikke aktiv da skaden skjedde. Prøv igjen, eller kontakt kundeservice.",
			},
		};
	};

	const checkForDeviceOrder = (imei: string) => {
		const device = phoneSearchData?.value?.find((d) => d.imei === imei);
		if (device?.deviceOrder) {
			deviceOrder.value = device?.deviceOrder;
		}
	};

	const searchIMEI = async (imeiNumber: string) => {
		phoneSearchData.value = await deviceReturns.searchIMEINumber(imeiNumber);
	};

	const searchIMEILoggedIn = async (imeiNumber: string) => {
		phoneSearchData.value = await deviceReturns.searchIMEINumberLoggedInUser(imeiNumber);
	};

	const setStepCompleted = async ({ step }: { step: Partial<TradeInSteps> }) => {
		await updateTradeInItem({ tradeInInfo: { steps: { ...tradeInItem?.value?.metaData?.steps, ...step } } });
	};

	const addTradeInItem = async ({ tradeInInfo }: { tradeInInfo?: TradeInInfo }) => {
		const mappedTradeInItem = mapTradeInItem({
			tradeInInfo,
		});
		if (tradeInItem?.value) {
			openModal({
				heading: "Vil du bytte enhet?",
				subHeading:
					"For å kunne legge til en enhet i handlekurven, må du først fullføre innbytteprosessen for enheten som allerede ligger i handlekurven. Hvis du legger til en ny enhet før prosessen er fullført, vil den eksisterende enheten bli byttet ut.",
				actions: [
					{
						props: {
							text: `Fortsett med ${tradeInItem?.value?.metaData?.phoneData?.manufacturer} ${tradeInItem?.value?.metaData?.phoneData?.model}`,
							secondary: true,
						},
						action: async () => {
							closeModal();
							router.push("devices.page");
						},
					},
					{
						props: { text: `Bytt til ${tradeInInfo?.phoneData?.manufacturer} ${tradeInInfo?.phoneData?.model}` },
						action: async () => {
							closeModal();
							await store.dispatch("shoppingCart/simpleAdd", mappedTradeInItem);
							router.push("devices.page");
						},
					},
				],
			});
		} else {
			await store.dispatch("shoppingCart/simpleAdd", mappedTradeInItem);
			router.push("devices.page");
		}
	};

	const updateTradeInItem = async ({ tradeInInfo }: { tradeInInfo: Partial<TradeInInfo> }) => {
		const mappedInfo: TradeInInfo = { ...tradeInItem?.value?.metaData, ...tradeInInfo } as TradeInInfo;

		const mappedTradeInItem = mapTradeInItem({ tradeInInfo: mappedInfo });

		await store.dispatch("shoppingCart/simpleAdd", mappedTradeInItem);
	};

	const removeTradeInItem = async () => {
		await store.dispatch("shoppingCart/remove", ShoppingCartItemEnum.TRADE_IN);
	};

	return {
		loading,
		error,
		isSubItem,
		needCustomerFeedback,
		deviceOrder,
		hasDeviceOrder,
		gradingQuestions,
		gradingAnswers,
		phoneData,
		tradeInItem,
		phoneSearchData,
		canContinue,
		gradingData,
		insuranceClaimData,
		steps,
		returnPath,
		isSwap,
		getEstimatedValue,
		searchForPhone,
		getGradingQuestions,
		addTradeInItem,
		updateTradeInItem,
		removeTradeInItem,
		submitInsuranceClaim,
		setInsuranceClaimErrorStatus,
		setInsuranceClaimOutsideDateError,
		addTradeInPhone,
		searchIMEI,
		setStepCompleted,
	};
};

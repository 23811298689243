const isBetween = (date: Date, startDate: Date, endDate: Date) => date >= startDate && date <= endDate;

const isBefore = (date: Date, compareDate: Date) => date < compareDate;

const isAfter = (date: Date, compareDate: Date) => date > compareDate;

const isToday = (date: Date) => {
	const today = new Date();
	return (
		date.getDate() == today.getDate() &&
		date.getMonth() == today.getMonth() &&
		date.getFullYear() == today.getFullYear()
	);
};

const isTomorrow = (date: Date) => {
	const today = new Date();
	return (
		date.getDate() == today.getDate() + 1 &&
		date.getMonth() == today.getMonth() &&
		date.getFullYear() == today.getFullYear()
	);
};

const getTime = (date: Date) => {
	const hours = date.getHours();
	const minutes = date.getMinutes();
	return `${hours}:${minutes}`;
};

export const formatYYYYMMDD = (date: Date): string => {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1).toString().padStart(2, "0");
	const day = date.getDate().toString().padStart(2, "0");
	return `${year}-${month}-${day}`;
};

export const substractDays = (date: Date, days: number): Date => {
	const newDate = new Date(date);
	newDate.setDate(date.getDate() - days);
	return newDate;
};

export const addDays = (date: Date, days: number): Date => {
	const newDate = new Date(date);
	newDate.setDate(date.getDate() + days);
	return newDate;
};

export const getAge = (date: Date, atDate: number = Date.now()): number => {
	const ageDifMs = atDate - date.valueOf();
	const ageDate = new Date(ageDifMs);
	return Math.max(0, ageDate.getUTCFullYear() - 1970);
};

export default { isBetween, isBefore, isAfter, isToday, isTomorrow, getTime, formatYYYYMMDD, getAge };

import mobileOrder from "~/integrations/mobile-order";
import telenorid from "~/telenorid/telenorid";
import { getOrderStructure } from "~/helpers/ecommerce/hardwareOrderHelper";
import Ajv from "ajv";
import { ShoppingCartItemEnum } from "~/components/ecommerce/types/ShoppingCartItemEnum";
import { useUserStore } from "~/pinia/platform/user/user.ts";

const ajv = new Ajv({ strict: false });

export const state = () => {
	return {
		guest: false,
		manualCurrentTerminal: null,
		graph: null,
		loading: false,
		msisdnDetails: [],
		validSubscription: false,
		paymentMethod: {},
		reservedNumbers: [],
		addonsUpdated: false,
		subscriptions: [],
		loadingIdentification: false,
		needsSubscription: false,
	};
};
export const mutations = {
	setCurrentTerminal(state, currentTerminal) {
		state.manualCurrentTerminal = currentTerminal;
	},
	setGraph(state, graph) {
		state.graph = graph;
	},
	setSubscriptions(state, subscriptions) {
		state.subscriptions = subscriptions;
	},
	setLoadingIdentification(state, value) {
		state.loadingIdentification = value;
	},
	setLoading(state, loading) {
		state.loading = loading;
	},
	setReservedNumbers(state, numbers) {
		state.reservedNumbers = numbers;
	},
	setMsisdnDetails(state, msisdn) {
		state.msisdnDetails.push(msisdn);
	},
	removeMsisdnDetails(state, accountId) {
		state.msisdnDetails = state.msisdnDetails.filter((x) => x.oldSubscriptionDetails.accountId !== accountId);
	},
	resetMsisdnDetails(state) {
		state.msisdnDetails = [];
	},
	setValidSubscription(state, valid) {
		state.validSubscription = valid;
	},
	setAddonsUpdated(state, status) {
		state.addonsUpdated = status;
	},
	setNeedSubscription(state, status = false) {
		state.needsSubscription = status;
	},
};
export const getters = {
	tradeInItem(state, getters, rootState) {
		return rootState["shoppingCart"]?.items?.find((item) => item.type === ShoppingCartItemEnum.TRADE_IN);
	},

	terminals(state, getters, rootState) {
		return rootState["shoppingCart"].items.filter(
			(item) => item.type === "hardware" || item.type === ShoppingCartItemEnum.TRADE_IN,
		);
	},
	ordersProcessed(state, getters) {
		return getters.terminals?.length && !getters.terminals.some((terminal) => !terminal?.metadata?.processed);
	},
	hasPaymentReference(state, getters) {
		return getters.terminals.some(
			(terminal) => terminal?.metadata?.payment?.method?.externalPaymentReference && !terminal?.metadata?.processed,
		);
	},
	isProcessed(state, getters) {
		return getters.terminals.some((terminal) => terminal?.metadata?.processed);
	},
	onlyAccessoryProducts(state, getters) {
		const itemsNotCountingAsAccessory = ["mobiltelefoner", "god som ny", "nettbrett", "smartklokker", "hardware"];
		return (
			getters.terminals?.length &&
			!getters.terminals.some((terminal) =>
				itemsNotCountingAsAccessory.includes(terminal?.metadata?.category?.toLowerCase()),
			)
		);
	},
	hasMobileOrders(state, getters) {
		return getters.terminals.length && !getters.onlyAccessoryProducts;
	},
	hasUnconfiguredTerminals(state, getters) {
		return (
			getters.terminals.some((terminal) => !terminal?.metadata?.terminalConfigured) &&
			!getters.hasPaymentReference &&
			!getters.isProcessed
		);
	},
	buyWithoutSubscription(state, getters) {
		return getters.terminals.some((terminal) => terminal?.metadata?.buyWithoutSubscription);
	},
	authSelected() {
		const userStore = useUserStore();
		return userStore.customer;
	},
	changeSwap(state, getters) {
		return getters.currentTerminal?.metadata?.changeSwap || !!getters.tradeInItem?.metaData?.hasSwap;
	},

	hasPreSelectedSwap(state, getters) {
		return getters?.tradeInItem?.metaData?.phoneData?.isPreSelected;
	},
	orderingNewSwap(state, getters) {
		return !!getters.currentTerminal?.metadata?.swap;
	},
	isNotWifiOrGPSProduct(state, getters) {
		const modelIsWifi = getters.currentTerminal?.metadata?.model?.toLowerCase === "wi-fi"; // checking exact model name because name can include wi-fi but also have 4g/5g support
		const gpsAndWifiNameCombinations = ["wi-fi", "wifi", "wi fi", "gps"];
		const isWifiOrGps = gpsAndWifiNameCombinations.some((combination) =>
			getters.currentTerminal?.metadata?.name.toLowerCase().includes(combination),
		);
		return !(modelIsWifi || isWifiOrGps);
	},
	isNotWifiProduct(state, getters) {
		return getters.isNotWifiOrGPSProduct; // Alias for isNotWifiOrGPSProduct to support current flow. Can be removed by eCommerce-team -Ryum
	},
	notOrderingNewSwap(state, getters) {
		return !getters.orderingNewSwap;
	},
	allTerminalsConfigured(state, getters) {
		let hasPaymentRefInQuery;
		// temp workaround to fix serverside issue
		try {
			hasPaymentRefInQuery = window && window.$nuxt?.$route?.query?.ref;
		} catch (e) {
			hasPaymentRefInQuery = false;
			console.error(e);
		}

		if (getters?.hasPaymentReference || getters?.ordersProcessed || !getters.terminals?.length || hasPaymentRefInQuery)
			return false;
		if (!getters.hasUnconfiguredTerminals) return true;
	},
	currentTerminal(state, getters) {
		if (state.manualCurrentTerminal) return state.manualCurrentTerminal;
		return getters.terminals.find(
			(terminal) => !terminal?.metadata?.terminalConfigured && terminal?.type === "hardware",
		);
	},
	isValidDeliveryAddress(state, getters) {
		return !!(getters.currentTerminal?.metadata?.deliveryDetails?.addressType === "STREET_ADDRESS");
	},
	isValidAddressId(state, getters) {
		const schema = {
			type: "object",
			properties: {
				addressId: { type: ["integer", "number"] },
				deliveryService: { type: "string" },
			},
			required: ["addressId", "deliveryService"],
		};
		return ajv.validate(schema, getters.currentTerminal?.metadata?.deliveryDetails);
	},
	hasValidDeliveryService(state, getters) {
		const deliveryDetails = getters.currentTerminal?.metadata?.deliveryDetails;

		return !!(
			deliveryDetails?.deliveryService === "POSTEN" ||
			(deliveryDetails?.deliveryService === "PORTERBUDDY" && deliveryDetails?.deliveryWindow?.token)
		);
	},
	orderStructure(state, getters, rootState) {
		return getOrderStructure(
			rootState["shoppingCart"].items.filter(
				(item) =>
					item.type === "hardware" ||
					item.type === "contact-information" ||
					item.type === ShoppingCartItemEnum.TRADE_IN,
			),
		);
	},
	currentTerminalAndTerminalsNotConfigured(state, getters) {
		return getters.currentTerminal && getters.hasUnconfiguredTerminals;
	},
	loading(state, getters, rootState) {
		return rootState["shoppingCart"].loading;
	},
	currentTerminalHasSubscription(state, getters) {
		return getters.currentTerminal?.subItems?.find((item) => item.type === "subscription");
	},
	currentTerminalInsurance(state, getters) {
		return getters.currentTerminal?.subItems?.find((item) => item.type === "agreement");
	},
	msisdnDetails(state) {
		return state.msisdnDetails;
	},
	validSubscription(state, getters) {
		return !!getters.currentTerminal?.subItems?.find((item) => item.type === "msisdn");
	},
	validSubscriptionAndNotChangeSwap(state, getters) {
		return getters.validSubscription && !getters.changeSwap;
	},
	validSubscriptionAndNotBuyWithoutSubscription(state, getters) {
		return getters.validSubscription && !getters.buyWithoutSubscription;
	},
	payingWithSwap(state, getters) {
		return !!getters.currentTerminal?.metadata?.swap?.agreementProductId;
	},
	hasNoSubscription(state, getters) {
		const isWifiProduct = !getters.isNotWifiOrGPSProduct;
		return !getters.validSubscription || isWifiProduct;
	},
	hasNoServicesToOffer(rootState) {
		return !(rootState.services?.filteredOfferedServices.length || !rootState.services?.hasBeenFetched);
	},
	wantsToTradeInSwapDevice(state, getters) {
		return !!getters?.tradeInItem?.metaData?.phoneData?.IMEINumber;
	},
	wantsToUseInsurance(state, getters) {
		return getters?.tradeInItem?.metaData?.phoneData?.steps?.wantsToUseInsurance;
	},
	swapHasDamageAndValidInsurance(state, getters) {
		return (
			(((getters?.tradeInItem?.metaData?.phoneData?.agreement?.categories?.includes("PLUS_INSURANCE") ||
				getters?.tradeInItem?.metaData?.phoneData?.agreement?.publicName?.includes("Plussforsikring")) &&
				getters?.tradeInItem?.metaData?.gradingAnswers?.some((answer) => answer.answerCode === "YES")) ||
				((getters?.tradeInItem?.metaData?.phoneData?.agreement?.categories?.includes("SCREEN_INSURANCE") ||
					getters?.tradeInItem?.metaData?.phoneData?.agreement?.publicName?.includes("Skjermforsikring")) &&
					getters?.tradeInItem?.metaData?.gradingAnswers?.some((answer) => answer.answerCode === "YES") &&
					!getters?.tradeInItem?.metaData?.gradingAnswers.some(
						(answer) => answer.answerCode === "YES" && answer.questionId !== "SCREEN_1",
					))) &&
			!getters.hasExistingTradeInOrder
		);
	},
	hasExistingTradeInOrder(state, getters, rootState) {
		return getters?.tradeInItem?.metaData?.existingSwapOrderId || rootState?.tradein?.metaData?.existingSwapOrderId;
	},
	swapHasDamageAndNoValidInsurance(state, getters) {
		return (
			(getters?.tradeInItem?.metaData?.phoneData?.agreement?.categories?.includes("SCREEN_INSURANCE") ||
				getters?.tradeInItem?.metaData?.phoneData?.agreement?.publicName?.includes("Skjermforsikring")) &&
			getters?.tradeInItem?.metaData?.gradingAnswers?.some(
				(answer) => answer.answerCode === "YES" && answer.questionId !== "SCREEN_1",
			) &&
			!getters.hasExistingTradeInOrder
		);
	},
	skipTradeinOrNoDamage(state, getters) {
		return (
			!getters?.tradeInItem?.metaData ||
			!getters?.tradeInItem?.metaData?.gradingAnswers?.some((answer) => answer.answerCode === "YES")
		);
	},
	noDamage(state, getters) {
		return (
			(!getters?.tradeInItem?.metaData?.gradingAnswers?.some((answer) => answer.answerCode === "YES") &&
				!!getters?.tradeInItem?.metaData?.gradingData?.deviceReturnId) ||
			getters.hasExistingTradeInOrder
		);
	},
	swapInsuranceClaimOk(state, getters) {
		return !!getters?.tradeInItem?.metaData?.insuranceClaimData?.dateOfDamage;
	},
	skipTradein(state, getters) {
		return !getters?.tradeInItem?.metaData;
	},
	paymentMethod(state) {
		return state.paymentMethod;
	},
	testWatchAndTabletInsurance() {
		// TO BE Removed once tablet && watch insurance is tested
		return true;
	},

	skipServices(state, getters) {
		// TO BE Removed once tablet && watch insurance is tested
		return getters.currentTerminal?.metadata?.category === "Smartklokker";
	},
	notSkipServices(state, getters) {
		// TO BE Removed once tablet && watch insurance is tested
		return !getters.skipServices;
	},
	needsIdentification(state, getters) {
		return getters.currentTerminal?.metadata?.downPayment && getters.hasNoSubscription;
	},
	notNeedsIdentification(state, getters) {
		return !getters.needsIdentification;
	},
	needSubscription(state) {
		return state.needsSubscription;
	},
	swapHasMsisdn(state, getters) {
		return !!getters?.tradeInItem?.metaData?.phoneData?.msisdn;
	},
	isTradeIn(state, getters) {
		return !getters.tradeInItem?.metaData?.hasSWAP;
	},
};
export const actions = {
	async setValidSubscription({ commit }, value) {
		commit("setValidSubscription", value);
	},
	async setAddonsUpdated({ commit }, value) {
		commit("setAddonsUpdated", value);
	},
	async init({ commit, dispatch }) {
		commit("setLoading", true);

		await telenorid.getOrLoginUser();

		try {
			await dispatch("shoppingCart/init", null, { root: true });

			console.log("Ready");
		} catch (err) {
			console.error(err);
			throw err;
		} finally {
			commit("setLoading", false);
		}
	},
	async validateOrder({ getters }) {
		try {
			return await mobileOrder.validateOrder(getters.orderStructure);
		} catch (e) {
			console.error(e.response?.data || e);
		}
	},
};

import apigeeIntegration from "./self-service-adapter";
import { stringifyQuery } from "~/components/security-consumer/epost/common/epostHelpers";

const api = apigeeIntegration("/hardware-subscriptions/v1");

const getInsuranceProducts = async ({ imei, gtin, subscriptionProductOfferId, hardwareType = "MOBILE_PHONE" }) => {
	return api.get(
		`/mobile-subscription-products/${subscriptionProductOfferId}/insurance-products${stringifyQuery({
			imei,
			gtin,
			hardwareType,
		})}`,
	);
};

const getOverviewOfAgreements = async () => {
	return api.get("/users/myself/overview");
};

const getSwapDevices = async () => {
	return api.get("/users/myself/swap-subscriptions");
};

const getDownPaymentDevices = async () => {
	return api.get("/users/myself/downpayment-subscriptions");
};

const getSWAPAgreementWithSubscription = async ({ subscriptionProductId, monthlyListPrice }) => {
	return api.get(
		`/mobile-subscription-products/${subscriptionProductId}/swap-products?monthlyListPrice=${monthlyListPrice}`,
	);
};

const getSWAPAgreementWithoutSubscription = async (monthlyListPrice) => {
	return api.get(`/hardware-subscription-products/swap-products?monthlyListPrice=${monthlyListPrice}`);
};

const getDownPaymentAgreementWithSubscription = async ({ subscriptionProductId, totalPrice }) => {
	return api.get(
		`/mobile-subscription-products/${subscriptionProductId}/downpayment-products?totalPrice=${totalPrice}`,
	);
};

const getDownPaymentAgreementWithoutSubscription = async (totalPrice) => {
	return api.get(`/hardware-subscription-products/downpayments-products?totalPrice=${totalPrice}`);
};

const getUsersInuredDevices = async () => {
	return api.get("users/myself/insurance-subscriptions", { cache: { override: true } });
};

const getProofOfInsurance = async (imei) => {
	return api.get(`insurance-subscriptions/${imei}/proof-of-insurance`);
};

const getInsuranceProductsForUpgradingOrDowngrading = async (imei) => {
	return api.get(`insurance-subscriptions/${imei}/available-products`, { cache: { override: true } });
};

const validateChange = async ({ imei, newInsuranceProductId }) => {
	return api.put(`insurance-subscriptions/${imei}/validation`, { newInsuranceProductId: newInsuranceProductId });
};

const changeInsurance = async ({ imei, newInsuranceProductId }) => {
	return api.put(`insurance-subscriptions/${imei}`, { newInsuranceProductId: newInsuranceProductId });
};

export default {
	getInsuranceProducts,
	getOverviewOfAgreements,
	getSwapDevices,
	getSWAPAgreementWithSubscription,
	getSWAPAgreementWithoutSubscription,
	getUsersInuredDevices,
	getProofOfInsurance,
	getInsuranceProductsForUpgradingOrDowngrading,
	changeInsurance,
	validateChange,
	getDownPaymentDevices,
	getDownPaymentAgreementWithoutSubscription,
	getDownPaymentAgreementWithSubscription,
};

import clickAndCollectIntegration from "~/integrations/click-and-collect-integration";
import shippingIntegration from "~/integrations/shipping-integration";
import dateHelper from "~/helpers/dateHelper";

const getDefaultProductState = () => {
	return {
		accessories: [],
		accessoryFor: [],
		additionalfilters: [],
		allowCollectAtStore: false,
		attributes: {},
		brand: "",
		bundleContent: [],
		categories: [],
		category: "",
		categoryIds: [],
		categorynames: [],
		cleanName: "",
		clickAndCollectStores: [],
		color: "",
		colorCode: "",
		condition: "",
		createdDate: "",
		description: "",
		descriptionSummary: "",
		fallbackId: "",
		gtin: "",
		id: null,
		ignoreInventory: false,
		imageLink: "",
		imageLink400pxHeight: "",
		images: [],
		itemGroupId: -1,
		link: "",
		loading: false,
		loadingClickAndCollect: true,
		memory: "",
		model: "",
		numberOfStoresWithProduct: 0,
		parentCategory: "",
		parentId: -1,
		price: "",
		priceWithVat: "",
		priority: "0",
		rating: -1,
		recommendedAccessories: [],
		requiresItemInStock: false,
		reviewCount: -1,
		rootCategory: "",
		salePrice: "",
		salePriceWithVat: "",
		sapProductId: "",
		size: "",
		sizes: [],
		stockValue: -1,
		swapDiscountedMonthlyPrice: -1,
		swapDiscountedTotalPrice: -1,
		swapMonthlyPrice: -1,
		swapTotalPrice: -1,
		title: "",
		type: "",
		updatedDate: "",
		variantParentId: "",
		variants: [],
		webonProductId: "",
		memberPrice: undefined,
		disableDownPayment: false,
	};
};

export const state = () => {
	return {
		product: getDefaultProductState(),
		detailsTab: 0,
		totalDetailsTabs: 0,
		variantAccessories: [],
		selectedWebShopOption: "SWAP",
		clickAndCollectModalOpen: false,
		deliveryAvailability: null,
		deliveryPostalCode: "",
		loadingPorterBuddyAvailability: false,
		categoriesThatDontRequireStock: ["Mobiltelefoner", "Smartklokker", "Nettbrett", "Ruter"],
		categoriesThatRequireStock: ["God Som Ny", "Lyd", "Tilbehør"],
	};
};

const getSubCategory = (categories) => {
	if (!categories || categories.length < 2) return null;

	const subCategory = categories.find(
		(category) => category.parentId === categories.find((cat) => cat.id === category.parentId)?.id,
	);

	return subCategory?.name;
};

export const mutations = {
	setProduct(state, product) {
		try {
			useAnalytics().addProduct({
				id: product.id,
				name: product.cleanName,
				categoryName: product.category,
				subCategoryName: getSubCategory(product.categories),
				brand: product.brand,
				price: Math.round(product.salePriceWithVat) || Math.round(product.priceWithVat),
				quantity: 1,
				model: product.model,
				inventoryCount: product.inventory?.[0]?.amount,
				details: {
					color: product.color,
					memory: product.memory,
					grading: product.condition,
					size: product.size,
				},
			});
		} catch {
			/* just to be sure not to break anything */
		}

		state.product = {
			...product,
			clickAndCollectStores: [],
			loadingClickAndCollect: false,
		};
	},
	setDetailsTab(state, tab) {
		state.detailsTab = tab;
	},
	setTotalDetailsTabs(state, total) {
		state.totalDetailsTabs = total;
	},
	setSelectedWebShopOption(state, value) {
		state.selectedWebShopOption = value;
	},
	setLoadingClickAndCollect(state, loading) {
		state.product.loadingClickAndCollect = loading;
	},
	setClickAndCollectStores(state, stores) {
		state.product.clickAndCollectStores = stores;
	},
	resetProduct(state) {
		Object.assign(state.product, getDefaultProductState());
	},
	setVariantAccessories(state, accessories) {
		state.variantAccessories = accessories;
	},
	toggleClickAndCollectModal(state) {
		state.clickAndCollectModalOpen = !state.clickAndCollectModalOpen;
	},
	setNumberOfStoresWithProduct(state, value) {
		state.product.numberOfStoresWithProduct = value || 0;
	},
	setDeliveryAvailability(state, value) {
		state.deliveryAvailability = value;
	},
	setDeliveryPostalCode(state, value) {
		state.deliveryPostalCode = value;
	},
	setLoadingPorterBuddyAvailability(state, value) {
		state.loadingPorterBuddyAvailability = value;
	},
};
export const actions = {
	async getNumberOfStoresWithProduct({ commit }, hardwareProductId) {
		commit("setLoadingClickAndCollect", true);

		if (!hardwareProductId) return;

		try {
			const { dealerCount } = await clickAndCollectIntegration.checkAvailability(hardwareProductId);
			commit("setNumberOfStoresWithProduct", dealerCount);
		} catch (e) {
			console.error(e);
		} finally {
			commit("setLoadingClickAndCollect", false);
		}
	},
	setVariantAccessories({ commit }, payload) {
		commit("setVariantAccessories", payload);
	},
	setTotalDetailsTabs({ commit }, payload) {
		commit("setTotalDetailsTabs", payload);
	},
	async checkPorterBuddyDeliveryAvailability({ commit, getters }, postalCode) {
		const isPostalCodeValid = /^[0-9]{4}$/.test(postalCode);

		if (isPostalCodeValid) {
			commit("setLoadingPorterBuddyAvailability", true);

			try {
				const res = await shippingIntegration.porterBuddy.availability(
					{ postalCode },
					{ sameDayDelivery: getters.sameDayDelivery },
				);
				commit("setDeliveryAvailability", res);
				commit("setDeliveryPostalCode", postalCode);
				commit("setLoadingPorterBuddyAvailability", false);
			} catch (e) {
				console.error(e);
				commit("setLoadingPorterBuddyAvailability", false);
			}
		}
	},
};
export const getters = {
	segment: (state, getters, rootState) => {
		return {
			privat: "consumer",
			bedrift: "business",
		}[rootState.siteSegment];
	},
	productHasNoAccessories(state) {
		return state.product.id && state.product.accessories.length === 0 && state.variantAccessories.length === 0;
	},
	productHasAccessories(state) {
		return (state.product.id && state.product.accessories.length > 0) || state.variantAccessories.length > 0;
	},
	productMemberPrice(state) {
		const activeMemberPrice = state.product?.memberPrice?.endDate
			? dateHelper.isBetween(
					new Date(),
					new Date(state.product?.memberPrice?.startDate),
					new Date(state.product?.memberPrice?.endDate),
				)
			: dateHelper.isAfter(new Date(), new Date(state.product?.memberPrice?.startDate));

		return activeMemberPrice && state.product?.memberPrice ? state.product?.memberPrice : undefined;
	},
	isDeliveryWindowOpen(state) {
		if (!state.deliveryAvailability) {
			return false;
		}

		return state.deliveryAvailability?.deliveryWindows?.some((deliveryWindow) =>
			dateHelper.isBefore(new Date(), new Date(deliveryWindow.expiresAt)),
		);
	},
	firstDeliveryWindow(state) {
		if (!state.deliveryAvailability && state.deliveryAvailability?.deliveryWindows.length === 0) {
			return null;
		}

		return state.deliveryAvailability?.deliveryWindows?.reduce((earliest, current) => {
			return dateHelper.isBefore(new Date(current.start), new Date(earliest.start)) ? current : earliest;
		});
	},
	sameDayDelivery(state, getters, rootState, rootGetters) {
		// Check if the cart contains a pre-attached screen protector, if so, we can't offer same day delivery.
		return !rootGetters["shoppingCart/visibleCartItems"]?.some((item) =>
			item?.subItems?.some(
				(subItem) =>
					subItem?.metadata?.preConfigId && subItem?.metadata?.badge?.toLowerCase().includes("ferdig påsatt"),
			),
		);
	},
};
